<template>
  <registry-card
    v-if="show && type === 'card'"
    :registry-id="registryId"
    :record-id="recordId"
    :card-id="cardId"
    :readonly="readOnly"
    :initial-data="initialData"
    @recordAdded="addCallback"
    @recordEdited="updateCallback"
  ></registry-card>
  <dashboard
    v-else-if="show && type === 'dashboard'"
    :id="dashboardId"
    ref="dashboard"
    :show-breadcrumb="showBreadcrumb"
    :breadcrumb-by-button="breadcrumbByButton"
  ></dashboard>
</template>

<script>
import RegistryCard from '@/components/RegistryCard/index.vue'
import mixin from '@/components/InterfaceEditor/components/mixins'
import Dashboard from '@/components/Dashboard/index.vue'
export default {
  name: 'a-frame',
  components: { Dashboard, RegistryCard },
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    showBreadcrumb: {
      type: Boolean,
      default: true,
      description: 'show_breadcrumb'
    },
    defaultDashboardId: {
      type: Number,
      editor: 'Dashboards',
      description: 'default_dashboard'
    },
    defaultCard: {
      type: String,
      description: 'default_card_id'
    },
    frame: {
      type: Object,
      editor: 'frame',
      description: 'default_card',
      default: () => {
        return {
          registryId: null,
          cardId: null,
          recordId: null
        }
      }
    }
  },
  data () {
    return {
      cardId: null,
      registryId: null,
      recordId: null,
      readOnly: false,
      updateCallback: () => {},
      addCallback: () => {},
      initialData: {},
      show: false,
      type: 'card',
      dashboardId: null,
      breadcrumbByButton: true
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    defaultCardRecordId (value) {
      if (this.frame.registryId && this.frame.cardId && this.frame.recordId) {
        if (!value) {
          console.warn('Не найден id для открытия карточки по умолчанию')
          return false
        }
        this.openCard({
          cardId: this.frame.cardId,
          registryId: this.frame.registryId,
          recordId: value
        })
      }
    }
  },
  computed: {
    defaultRecordId () {
      let [registryId, recordId, cardId] = this.defaultCard.split(';')
      if (!parseInt(recordId)) {
        recordId = this.getModel()[recordId]
      }

      return recordId
    },
    defaultCardRecordId () {
      return this.getModel()[this.frame.recordId]
    }
  },
  mounted () {
    if (this.defaultDashboardId) {
      this.openDashboard({
        dashboardId: this.defaultDashboardId
      })
    } else if ((this.defaultCard || '').length) {
      let [registryId, recordId, cardId] = this.defaultCard.split(';')
      if (!registryId || !recordId || !cardId) {
        console.warn('Не указаны обязательные параметры для открытия карточки')
        return false
      }

      this.openCard({
        cardId: cardId,
        registryId: registryId,
        recordId: this.defaultRecordId
      })
    } else if (this.frame.registryId && this.frame.cardId && this.frame.recordId) {
      this.openNewDefaultCardId()
    }
  },
  methods: {
    openDashboard ({ dashboardId, title, breadcrumb }) {
      if (typeof breadcrumb !== 'undefined') {
        this.breadcrumbByButton = breadcrumb
      }
      if (this.show === true && this.type === 'dashboard' && this.$refs.dashboard) {
        const dashboards = this.$refs.dashboard.openedCards.filter(item => item.isDashboard)
        // const issetDashboardId = dashboards.length > 0 ? dashboards[dashboards.length - 1].id : null
        let findDashboard = dashboards.find(findId => dashboardId === findId.id)
        let idx = dashboards.findIndex(item => dashboardId === item.id)
        let issetDashboardId = findDashboard ? findDashboard.id : null
        // проверка на наличие дашборда в массиве
        if (dashboardId !== issetDashboardId) {
          this.$refs.dashboard.openDashboardCard(dashboardId, title)
          return
        }
        if (idx > -1) {
          this.$refs.dashboard.openedCards = this.$refs.dashboard.openedCards.slice(0, idx + 1)
          return
        }
      }
      this.show = false
      this.type = 'dashboard'
      this.dashboardId = dashboardId
      this.$nextTick(() => {
        this.show = true
      })
    },
    openCard ({ cardId, registryId, recordId, readOnly, initialData = {}, updateCallback = () => {}, addCallback = () => {} }) {
      this.show = false
      this.type = 'card'
      this.cardId = cardId
      this.recordId = recordId
      this.registryId = registryId
      this.addCallback = addCallback
      this.updateCallback = updateCallback
      this.initialData = initialData
      this.readOnly = readOnly
      this.$nextTick(() => {
        this.show = true
      })
    },
    openNewDefaultCardId () {
      if (!this.defaultCardRecordId) {
        console.warn('Не найден id для открытия карточки по умолчанию')
        return false
      }
      this.openCard({
        cardId: this.frame.cardId,
        registryId: this.frame.registryId,
        recordId: this.defaultCardRecordId
      })
    }
  }
}
</script>

<style scoped>

</style>
